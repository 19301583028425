.at-sm {
  max-width: 355px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-md {
  max-width: 755px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-popupimg {
  margin: 0;
  width: 100%;
  float: left;
  background: #fff;
  position: relative;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}

.at-popupimg img {
  width: 100%;
  height: auto;
  opacity: 0.95;
  display: block;
  border-radius: 5px 5px 0 0;
}

.at-closepopup {
  top: 20px;
  z-index: 9;
  right: 20px;
  width: 11px;
  height: 11px;
  cursor: pointer;
  position: absolute;
}

.at-popupcontent {
  width: 100%;
  float: left;
  position: relative;
  padding: 75px 40px 35px;
}

.at-popupicon {
  left: 50%;
  top: -50px;
  width: 100px;
  height: 100px;
  display: block;
  background: #fff;
  line-height: 100px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.at-popupicon img {
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 20px 0 rgba(1, 138, 205, 0.2);
  box-shadow: 0 3px 20px 0 rgba(1, 138, 205, 0.2);
}

.at-popupicon i {
  display: block;
  color: #ff0000;
  font-size: 60px;
  line-height: 100px;
}

.at-popupcontent h3 {
  width: 100%;
  float: left;
  color: #262626;
  font-size: 28px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  text-transform: none;
  font-family: "Poppins", sans-serif;
}

.at-popupcontent em {
  clear: both;
  display: block;
  color: #454545;
  font-size: 14px;
  margin: 0 0 30px;
  line-height: 14px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
}

.at-btnarea {
  width: 100%;
  float: left;
  padding: 50px 0 0;
  text-align: center;
}

/*.at-popupcontent h3 {
    margin: 0 0 20px;
    text-align: left;
}*/

.at-popuphead {
  width: 100%;
  float: left;
  text-align: center;
  padding: 22px 20px;
  position: relative;
  background: #02a2de;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}

.at-popuphead h3 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
