/*==============================================
			Dictionary Style					
==============================================*/
.at-dictionaryholder {
  max-width: 470px;
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
}
.at-dictionaryhead {
  width: 100%;
  float: left;
  position: relative;
  padding: 15px 25px;
  background: #02a2de;
}
.at-dictionaryhead h2 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.at-dictionarycontent {
  width: 100%;
  float: left;
  background: #fff;
  min-height: 450px;
}
.at-dictionarysearch {
  width: 100%;
  float: left;
  background: #fff;
  padding: 15px 20px;
  -webkit-box-shadow: 0 5px 30px 0 rgba(23, 103, 141, 0.11);
  box-shadow: 0 5px 30px 0 rgba(23, 103, 141, 0.11);
}
.at-wordsearcform {
  width: 100%;
  float: left;
}
.at-wordsearcform fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}
.at-wordsearcform fieldset .form-group {
  margin: 0;
  width: 100%;
  float: left;
}
.at-wordsearcform fieldset .form-group input {
  border: 0;
  width: 100%;
  height: 42px;
  border-radius: 3px;
  background: #f7f7fb;
}
.at-buttonholder {
  width: 100%;
  float: left;
  padding: 10px 0 0;
}
.at-buttonholder label {
  margin: 0;
  float: left;
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  position: relative;
  border-radius: 3px;
  background: #f7f7fb;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.at-buttonholder label + label {
  margin: 0 0 0 10px;
}
.at-buttonholder label:after {
  width: 0;
  height: 0;
  left: 50%;
  opacity: 0;
  content: "";
  bottom: 100%;
  visibility: hidden;
  position: absolute;
  margin: 0 0 0 -3px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #02a2de;
}
.at-selected:after,
.at-selected:hover:after {
  opacity: 1 !important;
  visibility: visible !important;
}
.at-selected,
.at-selected:hover {
  color: #fff !important;
  background: #02a2de !important;
}

.at-alerterror {
  padding: 12px;
  font-size: 14px;
  margin: 0 0 15px;
  line-height: 14px;
}
.at-maindictionary,
.at-dictionaryholder {
  width: 100%;
  float: left;
}

.at-dictionaryholder {
  max-width: none;
  padding: 25px 25px 0;
}
.at-dictionaryholder figure {
  margin: 0;
  width: 100%;
  float: left;
}
