.spotlight {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-image: radial-gradient(
    circle,
    transparent 160px,
    rgba(0, 0, 0, 0.85) 200px
  );
}

.at-hide {
  display: none;
}
