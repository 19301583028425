.at-forgotpasswordpopup {
  max-width: 490px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-popuphead {
  width: 100%;
  float: left;
  text-align: center;
  padding: 22px 20px;
  position: relative;
  background: #02a2de;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}

.at-popuphead h3 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.at-forgotcontent {
  width: 100%;
  float: left;
  padding: 50px;
  background: #fff;
}

.at-forgotcontent h3 {
  width: 100%;
  float: left;
  color: #262626;
  font-size: 14px;
  margin: 0 0 25px;
  line-height: 22px;
  text-align: center;
  text-transform: none;
  font-family: "Poppins", sans-serif;
}

.at-btnarea .at-themebtn {
  width: 100%;
}
