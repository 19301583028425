.tally-container {
  width: 100%;
  float: left;
  padding: 20px;
  position: relative;
}
.tally-container input {
  width: 40%;
}
.button-container {
  top: 50px;
  right: 20px;
  position: absolute;
}
.button-container button {
  border: 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 10px;
  box-shadow: none;
  line-height: 30px;
  border-radius: 3px;
  text-align: center;
  background: #f2f2f2;
}
.button-container button + button {
  margin: 0 0 0 5px;
}
.button-container button:hover,
.button-container button:focus {
  border: 0;
  color: #fff;
  outline: none;
  background: #02a2de;
}
.tally-container ol {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  padding: 15px 0 0;
}

.tally-container ol li {
  display: inline-block;
  height: 20px;
  border: 1px solid #000;
  margin-right: 4px;
}

.tally-container ol li:nth-child(5n) {
  transform: rotate(300deg);
  height: 30px;
  position: relative;
  left: -15px;
  top: 5px;
  margin-right: 1em;
  margin-top: -10px;
}
