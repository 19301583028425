/*==============================================
			Stop Watch Style						
==============================================*/
.at-holder {
  top: 10%;
  left: 10%;
  z-index: 11;
  display: block;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  border: 1px solid #02a2de;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.at-calculatorholder {
  min-width: 500px;
  width: 605px;
}

.at-stopwatchholder {
  max-width: 525px;
  width: 100%;
}

.at-abacushholder {
  max-width: 800px;
  width: 100%;
}
.at-diceholder {
  max-width: 500px;
  width: 100%;
  min-height: 400px;
}
.at-diceholder .at-stopwatchcontent {
  min-height: 353px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.at-excelholder {
  max-width: 700px;
  width: 100%;
}
.at-sumcreatorholder {
  max-width: 450px;
  width: 100%;
}
.at-tallyholder {
  max-width: 550px;
  width: 100%;
}
.at-watchhead {
  width: 100%;
  float: left;
  cursor: move;
  position: relative;
  padding: 15px 25px;
  background: #02a2de;
}

.at-watchhead h2 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.at-watchhead a {
  top: 50%;
  right: 15px;
  width: 36px;
  height: 36px;
  font-size: 18px;
  cursor: pointer;
  margin: -18px 0 0;
  line-height: 36px;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background: #e04f5f;
  color: #fff !important;
}

.at-dragimg {
  top: 50%;
  right: 20px;
  width: 20px;
  height: 5px;
  margin: -2px 0 0;
  position: absolute;
}

.at-stopwatchcontent {
  width: 100%;
  float: left;
  background: #fff;
}

.at-stopwatchcounter {
  width: 100%;
  float: left;
}

.at-counterarea {
  width: 100%;
  float: left;
  list-style: none;
  text-align: center;
  padding: 40px 30px 30px;
}

.at-counterarea li {
  padding: 0 6px;
  position: relative;
  display: inline-block;
  list-style-type: none;
  vertical-align: middle;
}

.at-counterarea li:first-child {
  padding-left: 0;
}

.at-counterarea li:last-child {
  padding-right: 0;
}

.at-counterarea li span {
  color: #000;
  opacity: 0.8;
  font-size: 50px;
  font-weight: 300;
  line-height: 50px;
  display: inline-block;
  vertical-align: middle;
  font-family: "Poppins", sans-serif;
}

.at-counterarea li.at-counterdots {
  padding: 0;
}

.at-counterarea li.at-counterdots span {
  margin: -35px 0 0;
}

.at-counterarea li em {
  color: #000;
  opacity: 0.5;
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 0 0;
  line-height: 16px;
  font-style: normal;
  text-transform: uppercase;
}

.at-counterbtns {
  width: 100%;
  float: left;
  list-style: none;
  margin: 0 0 -45px;
  text-align: center;
}

.at-counterbtns li {
  padding: 0 15px;
  display: inline-block;
  list-style-type: none;
  vertical-align: middle;
}

.at-counterbtns li a {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  background: #02a2de;
}

.at-counterbtns li.at-btnpause a {
  width: 83px;
  height: 83px;
  line-height: 83px;
}

.at-counterbtns li.at-btnpause a:after {
  top: -10px;
  left: -10px;
  content: "";
  width: 103px;
  height: 103px;
  border-radius: 50%;
  position: absolute;
  border: 1px dashed #b9cede;
}

.at-counterhistoryhold {
  width: 100%;
  float: left;
  background: #fff;
  padding: 195px 0 30px;
  -webkit-box-shadow: inset 0 0 50px 10px rgba(23, 103, 141, 0.11);
  box-shadow: inset 0 0 50px 10px rgba(23, 103, 141, 0.11);
}

.at-history {
  width: 100%;
  float: left;
  font-size: 16px;
  list-style: none;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
}

.at-history li {
  width: 100%;
  float: left;
  line-height: inherit;
  list-style-type: none;
  padding: 20px 35px 20px 20px;
}

.at-history li:nth-child(odd) {
  background: #f7fcfe;
}

.at-history li em,
.at-history li h3,
.at-history li span {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.at-history li h3 {
  color: #02a2de;
  margin: 0 0 0 12px;
}

.at-history li span {
  float: right;
}
