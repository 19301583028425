.wrapper {
  width: 1050px;
  margin: 30px auto;
  position: relative;
}

.main:after {
  clear: both;
  content: "";
  display: table;
}

.main li {
  width: 56px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  position: relative;
  font-size: 18px;
  float: left;
  padding: 0 2px;
  margin: 1px;
  cursor: pointer;
  padding-top: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}

.main li span {
  display: block;
  line-height: 1;
  font-size: 9px;
  color: black;
  padding-top: 7px;
}

.main .empty {
  border: none;
  box-shadow: none;
  cursor: default;
}

.deactivate {
  opacity: 0.5;
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
}

.main li:before,
.main li:after {
  content: attr(data-pos);
  position: absolute;
  top: 3px;
  left: 3px;
  color: black;
  font-size: 8px;
  line-height: 1;
}

.main li:after {
  content: attr(data-nb);
  left: auto;
  right: 3px;
}

.main li:hover {
  transform: scale(2);
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.main .empty:hover {
  box-shadow: none;
  transform: none;
}

.type-0 {
  background: #fff;
}
.type-1 {
  background: #dddddd;
}
.type-2 {
  background: #a8bffa;
}
.type-3 {
  background: #f8b707;
}
.type-4 {
  background: #f3f300;
}
.type-5 {
  background: #3bd93b;
}
.type-6 {
  background: #dd9999;
}
.type-7 {
  background: #4caffa;
}
.type-8 {
  background: #ffaa88;
}
.type-9 {
  background: #ddaacc;
}

.cat-0 {
  color: #222;
}
.cat-1 {
  color: #0000dc;
}
.cat-2 {
  color: #b10601;
}
.cat-3 {
  color: #555;
}

.legend {
  position: absolute;
  top: 0;
  left: 27%;
  padding: 10px;
  font-size: 11px;
  background: #f1edec;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  box-shadow: inset 0 1px 1px white, inset 0 -5px 3px #dddcdb,
    0 0 10px rgba(0, 0, 0, 0.2);
}

.legend:after {
  content: "";
  display: table;
  clear: table;
}

.legend ul {
  float: left;
  list-style: none;
}

.legend .list-2 {
  margin: 0;
  width: 360px;
  padding: 0 0 0 40px;
}

.legend .list-1 li {
  margin: 7px 0;
}
.legend .list-1 li:first-of-type {
  margin-top: 2px;
}

.legend .list-1 span {
  border: 1px solid black;
  display: inline-block;
  padding: 3px;
  width: 20px;
  text-align: center;
  height: 20px;
  margin-right: 5px;
}

.legend .list-2 li {
  margin: 2px;
  padding: 3px;
  float: left;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 48%;
  cursor: pointer;
}
