.container-flex {
  margin: 10px auto;
  width: 100%;
  float: left;
  height: 549px;
}

.abacus {
  width: 100%;
  height: 82%;
  margin-bottom: 10px;
}

/*----- Abacus layout ------*/

.framework {
  width: 100%;
  height: 100%;
  padding: 1px;
  border-radius: 5px;
}

.upper-row {
  width: 90%;
  height: 22%;
  margin: 25px auto 0;
  border-radius: 5px;
}

.lower-row {
  width: 90%;
  height: 60%;
  border-radius: 5px;
  margin: 18px auto 0;
}

.col-10 {
  display: block;
  float: left;
  width: 10%;
  height: 100%;
  margin: 0;
}

/*------ abacus components ------*/

.vertical-line {
  width: 12%;
  height: 100%;
  background-color: #e04f5f;
  border: 1px solid #e04f5f;
  margin: 0 auto;
  z-index: -1;
}

.framework {
  float: left;
}

.framework img {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.ball5 {
  bottom: 96px;
}

.ball4 {
  bottom: 192px;
}

.ball3 {
  bottom: 212px;
}

.ball2 {
  bottom: 232px;
}

.ball1 {
  bottom: 252px;
}

/*------- operators and display layout -------*/

.operators {
  float: left;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.operator-button {
  margin: 0 5px;
  cursor: pointer;
  padding: 0 10px;
  font-size: 25px;
  line-height: 30px;
  border-radius: 5px;
  background: #e04f5f;
  display: inline-block;
  vertical-align: middle;
  color: #fff !important;
}

.display {
  width: 100%;
  float: left;
  min-height: 40px;
  border-radius: 5px;
}

.display p {
  margin: 0;
  color: #000;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}
